$(function () {
  var headerHight = 40;
  $('a[href^=#]').click(function(){
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top-headerHight;
    $("html, body").animate({scrollTop:position}, 600, "swing");
    return false;
  });

  $(window).load(function () {
    var header = $('header'),
        offset = header.offset();
    $(window).scroll(function () {
      if($(window).scrollTop() > offset.top + 60) {
        header.addClass('fixed');
      } else {
        header.removeClass('fixed')
      }
    });
  });

  //sp menu
  $('.sp-menu').click(function() {
    $('.header-nav').toggleClass('active');
    $(this).toggleClass('active');
    $('header').toggleClass('active');
  });
  $('.header-nav-item').click(function() {
    $('.header-nav').toggleClass('active');
    $('.sp-menu').toggleClass('active');
    $('header').toggleClass('active');
  });

});